import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const RechargePage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  return (
    <div className="recharge-index uni-page-wrapper">
      <div className="head">
        <div className="head__bg">
          <div className="head__row">
            <div className="head__p">
              <div className="head__title">Lời khuyên tốt</div>
              <div className="head__title">Mua tiền bằng thẻ ngân hàng</div>
              <div className="head__title">
                Vui lòng liên hệ dịch vụ khách hàng để nạp tiền
              </div>
            </div>
            <div className="head__nameid">
              Số dư: {formatNumber(user?.balance, "0,0.[0000]")}
            </div>
          </div>
        </div>
      </div>
      <div className="uni-list">
        <div className="uni-list-item">
          <Link
            to="/support"
            className="uni-list-item__container uni-list-item--first"
          >
            <div className="uni-list-item__icon">
              <div className="uni-list-item__icon-img" />
            </div>
            <div className="uni-list-item__content">
              <div className="uni-list-item__content-title">Nạp tiền ngay</div>
            </div>
            <div className="uni-list-item__extra">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
        </div>
        <div className="uni-list-item">
          <Link
            to="/support"
            className="uni-list-item__container uni-list-item--first"
          >
            <div className="uni-list-item__icon">
              <div className="uni-list-item__icon-img" />
            </div>
            <div className="uni-list-item__content">
              <div className="uni-list-item__content-title">
                Đi mua tiền ngay
              </div>
            </div>
            <div className="uni-list-item__extra">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
        </div>
        <div className="uni-list-item">
          <Link
            to="/support"
            className="uni-list-item__container uni-list-item--first"
          >
            <div className="uni-list-item__icon">
              <div className="uni-list-item__icon-img" />
            </div>
            <div className="uni-list-item__content">
              <div className="uni-list-item__content-title">Nạp tiền USDT</div>
            </div>
            <div className="uni-list-item__extra">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
});

export default RechargePage;
