import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { RoomApi } from "states/api/room";
import { useQuery } from "@tanstack/react-query";

function RoomPage() {
  const {
    authStore: { user },
  } = useStores();
  const [params] = useSearchParams();
  const [activeTab, setActiveTab] = useState("USDT");

  const { data: listData, refetch } = useQuery(
    ["room", "list", activeTab],
    () =>
      RoomApi.getList({
        params: {
          page: 1,
          limit: 20,
          type: activeTab,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <HeaderTitle title="Phòng" />
      <div className="page-container room-index">
        <div className="uni-inline-item">
          <div className="uni-view">
            <div className="uni-h4 uni-card-link">
              {formatNumber(user?.balance, "0,0.[0000]")}
            </div>
            <div className="uni-h6">Số dư tài khoản</div>
          </div>
          <div className="uni-view">
            <div className="uni-h6 uni-inline-right">
              Khối lượng giao dịch hôm qua: 4329422
            </div>
            <div className="uni-h6 uni-inline-right">
              Khối lượng giao dịch hôm nay: 559514
            </div>
          </div>
        </div>
        <div className="tabs" style={{ marginTop: 5 }}>
          <div
            className={classNames("tab", {
              active: activeTab === "USDT",
            })}
            onClick={() => setActiveTab("USDT")}
          >
            USDT
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "BTC",
            })}
            onClick={() => setActiveTab("BTC")}
          >
            BTC
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "ETH",
            })}
            onClick={() => setActiveTab("ETH")}
          >
            ETH
          </div>
        </div>
        <div className="uni-swiper-wrapper">
          <div className="uni-swiper-slides">
            {listData?.data?.map((item, index) => (
              <div
                className={`uni-flex uni-row image-view-${index}`}
                key={item?.id}
              >
                <Link
                  to={`/game/room/${item?.code}`}
                  className="uni-card image-view"
                >
                  <div className="image-view__row">
                    <div className="uni-h3">{item?.name}</div>
                    <div className="title">{item?.name}</div>
                    <div className="title title-mg">
                      Số tiền tối thiểu để tham gia phòng:{" "}
                      {formatNumber(item?.min_bet)}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default RoomPage;
