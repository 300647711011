import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faMoneyBillTransfer,
  faWallet,
  faRotate,
  faAngleRight,
  faListAlt,
  faLock,
  faBank,
  faUserLock,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { positionKeyText } from "_common/constants/statusType";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";

import icon1 from "assets/img/icon/user-tz.png";
import icon2 from "assets/img/icon/user-zd.png";
import icon3 from "assets/img/icon/user-fx.png";
import icon4 from "assets/img/icon/user-tx.png";
import icon5 from "assets/img/icon/user-lczd.png";
import icon6 from "assets/img/icon/user-xzjl.png";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, logo, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  const getProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="personal-page">
      <div className="header">
        <div className="user-card">
          <div className="user-info">
            <div className="vip-icon">
              <img src={user?.avatar_url} alt="" />
            </div>
            <div className="user-option">
              <div className="user-option-item">
                <span>ID:</span>
                <span>{user?.id} </span>
              </div>
              <div className="user-option-item">
                <span>SĐT:</span>
                <span>{user?.phone} </span>
              </div>
              <div className="user-option-item text-small">
                <span>Số dư:</span>
                <span>{formatNumber(user?.balance, "0,0.[0000]")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="grid uni-flex">
          <div className="grid__cell uni-flex-item">
            <div className="grid__name">Khối lượng giao dịch cá nhân</div>
            <div className="grid__name__red">
              {formatNumber(user?.total_bet, "0,0.[0000]")}
            </div>
          </div>
          <div className="grid__cell uni-flex-item">
            <div className="grid__name">Chi tiết hôm nay</div>
            <div className="grid__name__red">
              {formatNumber(user?.total_bet_today, "0,0.[0000]")}
            </div>
          </div>
        </div>
        <div className="grid uni-flex uni-row">
          <Link to="/support" className="uni-flex">
            <span>Nạp tiền</span>
            <FontAwesomeIcon icon={faCreditCard} />
          </Link>
          <Link to="/payment/withdraw" className="uni-flex">
            <span>Rút tiền</span>
            <FontAwesomeIcon icon={faWallet} />
          </Link>
        </div>
        <div className="cell-list">
          <Link to="/histories/game" className="cell-item">
            <div className="icon">
              <FontAwesomeIcon icon={faListAlt} />
            </div>
            <div className="name">Chi tiết đầu tư</div>
            <span className="arrow">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Link>

          <Link to="/histories/deposit" className="cell-item">
            <div className="icon">
              <FontAwesomeIcon icon={faListAlt} />
            </div>
            <div className="name">Lịch sử nạp tiền</div>
            <span className="arrow">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Link>
          <Link to="/histories/withdraw" className="cell-item">
            <div className="icon">
              <FontAwesomeIcon icon={faListAlt} />
            </div>
            <div className="name">Lịch sử rút tiền</div>
            <span className="arrow">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Link>
          <Link to="/member/setting" className="cell-item">
            <div className="icon">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="name">Quản lý mật khẩu</div>
            <span className="arrow">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Link>
          <Link to="/member/update-wallet" className="cell-item">
            <div className="icon">
              <FontAwesomeIcon icon={faWallet} />
            </div>
            <div className="name">Kết nối địa chỉ ví USDT</div>
            <span className="arrow">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Link>
          <Link to="/payment/bank" className="cell-item">
            <div className="icon">
              <FontAwesomeIcon icon={faBank} />
            </div>
            <div className="name">Kết nối ngân hàng</div>
            <span className="arrow">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Link>
          <div className="cell-item" onClick={() => onLogout()}>
            <div className="icon">
              <FontAwesomeIcon icon={faUserLock} />
            </div>
            <div className="name">Đăng xuất</div>
            <span className="arrow">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MemberPage;
