import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Portal } from "react-portal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { gameBetText } from "_common/constants/statusType";
import { formatNumber } from "_common/utils/formatValue";
import { get } from "lodash";

export default function ModalGameHelp({ gameInfo, open, onClose }) {
  const queryClient = useQueryClient();
  const {
    authStore: { avatars },
  } = useStores();

  const { data, isLoading, refetch } = useQuery(
    ["game", "review", "category"],
    () =>
      GameApi.getReview({
        params: {
          category: gameInfo?.category,
          level: gameInfo?.level,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="modal-history">
      <div className="mask" onClick={onClose} />
      <div className="modal-content">
        <div className="modal-body">
          <div className="list-result">
            <table>
              <tbody>
                {data?.data?.map((item, index) => (
                  <tr className="item" key={`item-${index?.toString()}`}>
                    <td className="info">
                      Số kỳ
                      <span> {item?.game_no}</span>
                    </td>
                    <td className="result">
                      <div className="lotteryNumber">
                        <span className="ball">{get(item, "number1", 1)}</span>
                        <span className="dau">+</span>
                        <span className="ball">{get(item, "number2", 1)}</span>
                        <span className="dau">+</span>
                        <span className="ball">{get(item, "number3", 1)}</span>
                        <span className="dau">=</span>
                        <span className="ball red">
                          {get(item, "total_number", 3)}
                        </span>
                      </div>
                    </td>
                    <td>
                      {gameBetText[item?.code1]},{gameBetText[item?.code2]},
                      {gameBetText[item?.code3]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
