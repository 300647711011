import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { ProfileApi } from "states/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";

const schema = yup
  .object({
    password: yup.string(),
    new_password: yup
      .string()
      .min(6, "Vui lòng nhập mật khẩu tối thiểu 6 ký tự")
      .required("Vui lòng nhập mật khẩu mới"),
    confirmed: yup
      .string()
      .required("Vui lòng nhập lại mật khẩu mới")
      .oneOf([yup.ref("new_password")], "Mật khẩu không khớp"),
  })
  .required();

const ChangeWalletPasswordPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, clear },
  } = useStores();
  const [params] = useSearchParams();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.changeWalletPassword({ params: variables }),
    {
      onSuccess: (res) => {
        navigate("/member/setting");
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "Thao tác thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  return (
    <>
      <HeaderTitle title="Mật khẩu giao dịch" />
      <div className="update-info-container">
        <form className="form-action form-horizontal">
          {user?.wallet_password && (
            <div
              className={classNames("form-item", {
                error: errors.password,
              })}
            >
              <div className="form_field">
                <InputPassword
                  className="field_control"
                  id="password"
                  placeholder="Vui lòng nhập mật khẩu rút tiền cũ"
                  {...register("password")}
                />
                <span className="error_t">{errors.password?.message}</span>
              </div>
            </div>
          )}
          <div
            className={classNames("form-item", {
              error: errors.new_password,
            })}
          >
            <div className="form_field">
              <InputPassword
                className="field_control"
                id="new_password"
                placeholder="Vui lòng nhập mật khẩu rút tiền mới"
                {...register("new_password")}
              />
              <span className="error_t">{errors.new_password?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.confirmed,
            })}
          >
            <div className="form_field">
              <InputPassword
                className="field_control"
                id="confirmed"
                placeholder="Vui lòng nhập lại mật khẩu rút tiền mới"
                {...register("confirmed")}
              />
              <span className="error_t">{errors.confirmed?.message}</span>
            </div>
          </div>
          <div className="form-item">
            <button
              type="button"
              className={classNames("btn btn-sub uni-btn-primary")}
              style={{ marginTop: 25 }}
              onClick={handleSubmit(onSubmit)}
            >
              Xác nhận
            </button>
          </div>
        </form>
      </div>
    </>
  );
});

export default ChangeWalletPasswordPage;
