import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";

import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPhone, faTimes } from "@fortawesome/free-solid-svg-icons";
import enImg from "assets/img/icon/en.png";

const schema = yup
  .object({
    username: yup.string().required("Bắt buộc điền"),
    password: yup.string().required("Bắt buộc điền"),
  })
  .required();

export default function LoginForm() {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-form-user" onSubmit={handleSubmit(handleLogin)}>
      <div>
        <ul>
          <li
            className={classNames("marginBottom", {
              error: errors.username,
            })}
          >
            <div className="form_In">
              <div className="field-group">
                <FontAwesomeIcon icon={faPhone} />
                <input
                  className="field_control"
                  id="username"
                  type="text"
                  placeholder="Vui lòng nhập tài khoản đăng nhập của bạn"
                  {...register("username")}
                />
                <FontAwesomeIcon icon={faTimes} />
              </div>

              <span className="error_t">{errors.username?.message}</span>
            </div>
          </li>

          <li
            className={classNames("marginBottom", {
              error: errors.password,
            })}
          >
            <div className="form_In">
              <div className="field-group">
                <FontAwesomeIcon icon={faLock} />
                <InputPassword
                  className="field_control"
                  id="password"
                  placeholder="Vui lòng nhập mật khẩu đăng nhập của bạn"
                  {...register("password")}
                />
              </div>
              <span className="error_t">{errors.password?.message}</span>
            </div>
          </li>
        </ul>
        <p style={{ fontSize: 18 }}>
          <Link to="/support">Quên mật khẩu</Link>
        </p>

        <div className="form_button">
          <input type="submit" className="btn btn-submit" value="Đăng nhập" />
        </div>
        <p className="signin-other-title">
          <Link to="/auth/register">Đăng ký ngay</Link>
        </p>
        <div className="login-footer">
          <Link to="/language" className="lang">
            <img src={enImg} alt="" />
            <span>Ngôn ngữ</span>
          </Link>
          <Link className="btn-support" to="/support">
            Hỗ trợ
          </Link>
        </div>
      </div>
    </form>
  );
}
