import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useStores } from "_common/hooks";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";

import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import { GameApi } from "states/api/game";
import { get } from "lodash";
import { formatNumber } from "_common/utils/formatValue";

const GameFooter = observer(({ gameInfo, timeEnd }) => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, item_ball, item_number, general },
    toastStore,
  } = useStores();
  const { dataGame, newResult, pushNotify } = useNotify();

  const [activeTab, setActiveTab] = useState("game1");
  const [valueBet, setValueBet] = useState();
  const [selectItem, setSelectItem] = useState([item_ball?.[0]]);

  useEffect(() => {
    if (dataGame?.status === "end_game") {
      setSelectItem([item_ball?.[0]]);
      setValueBet("");
    }
  }, [dataGame]);

  const finalTotal = useMemo(() => {
    const count = selectItem?.length || 0;

    return count * valueBet;
  }, [selectItem, general, valueBet]);

  const { mutate: onSubmit } = useMutation(
    () =>
      GameApi.betGame({
        params: {
          game_id: gameInfo?.id,
          items: selectItem,
          final_total: finalTotal,
          amount: valueBet,
          type: "bet",
        },
      }),
    {
      onSuccess: (res) => {
        if (typeof pushNotify === "function") {
          pushNotify({
            type: "betGame",
            status: "new_bet",
          });
        }
        queryClient.invalidateQueries(["get_profile"]);
        toastStore.success(res?.data?.msg || "Đặt cược thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        toastStore.error(errorMessage);
      },
    }
  );

  const handleSubmitForm = () => {
    if (timeEnd) return toastStore.error("Phiên game đang chuẩn bị đóng");
    onSubmit();

    return null;
  };

  const handleChooseItem = (item) => {
    setSelectItem([item]);
  };

  return (
    <div className="bg-footer">
      <div className="uni-flex Pc28-p">
        <div className="title">
          <div
            className={classNames("title-item", {
              active: activeTab === "game1",
            })}
            onClick={() => setActiveTab("game1")}
          >
            Giao dịch
          </div>
          <div
            className={classNames("title-item", {
              active: activeTab === "game2",
            })}
            onClick={() => setActiveTab("game2")}
          >
            Số
          </div>
        </div>
        <div className="Pc28-pfr">
          <div className="uni-scroll-view">
            <div className="uni-scroll-view-content">
              {activeTab === "game1" &&
                item_ball?.map((item, index) => (
                  <div
                    className={classNames("item ", {
                      active:
                        selectItem.findIndex((obj) => obj.code === item?.code) >
                        -1,
                    })}
                    key={item?.code}
                    onClick={() => handleChooseItem(item)}
                  >
                    <div className="item_text">{item?.name}</div>
                    <div className="item_html">*{item?.rate}</div>
                  </div>
                ))}
              {activeTab === "game2" &&
                item_number?.map((item, index) => (
                  <div
                    className={classNames("item item-number", {
                      active:
                        selectItem.findIndex((obj) => obj.code === item?.code) >
                        -1,
                    })}
                    key={item?.code}
                    onClick={() => handleChooseItem(item)}
                  >
                    <div className="item_text">{item?.name}</div>
                    <div className="item_html">*{item?.rate}</div>
                  </div>
                ))}
              <div className="uni-flex uni-pc">
                <div className="uni-input-wrapper">
                  <input
                    value={valueBet}
                    type="number"
                    inputMode="decimal"
                    className="uni-input-input uni-investment"
                    placeholder="Vui lòng nhập số tiền đầu tư"
                    onChange={(e) => setValueBet(Number(e?.target?.value))}
                  />
                </div>
                <button
                  type="button"
                  className="TZbtn uni-buy-btn"
                  onClick={() => handleSubmitForm()}
                >
                  Mua
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GameFooter;
