import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import lhc from "assets/img/lhc.png";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";

const coins = ["USDT", "BTC", "ETH"];

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function Profit() {
  const navigate = useNavigate();
  const {
    authStore: { order_random },
  } = useStores();

  const [ran, setRan] = useState();

  const renderItem = useMemo(() => {
    const lstName = [];

    for (let index = 0; index < 30; index += 1) {
      const indexCoin = randomInteger(0, coins.length - 1);
      const code = randomInteger(100, 999);
      const money = randomInteger(100, 10000);

      lstName.push({
        id: index,
        code,
        name: coins[indexCoin],
        money: money / 1.5,
      });
    }

    return lstName?.map((item, index) => (
      <li className="winner-item" key={`item-${index.toString()}`}>
        <span className="text blue">****{item?.code}</span>
        <span>{item?.name}</span>
        <span className="text blue">{formatNumber(item?.money, "0,0.00")}</span>
        <span>{moment().format("M/DD")}</span>
      </li>
    ));
  }, [ran]);

  const renderItem2 = useMemo(() => {
    const lstName = [];

    for (let index = 0; index < 30; index += 1) {
      const indexCoin = randomInteger(0, coins.length - 1);
      const code = randomInteger(100, 999);
      const money = randomInteger(100, 10000);

      lstName.push({
        id: index,
        code,
        name: coins[indexCoin],
        money: money / 1.5,
      });
    }

    return lstName?.map((item, index) => (
      <li className="winner-item" key={`item-${index.toString()}`}>
        <span className="text blue">****{item?.code}</span>
        <span>{item?.name}</span>
        <span className="text blue">{formatNumber(item?.money, "0,0.00")}</span>
        <span>{moment().format("M/DD")}</span>
      </li>
    ));
  }, [ran]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRan(new Date().getTime());
    }, 50000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="list-wrap">
      <div className="winner-list-wrap">
        <div className="title">
          <div className="text">Động lực tài chính</div>
        </div>
        <div className="winner-list ">
          <ul className="inner-wrap">
            <li className="winner-item head">
              <span>Người dùng</span>
              <span>Giao dịch</span>
              <span>Lợi nhuận</span>
              <span>Thời gian</span>
            </li>
          </ul>
          <ul className="inner-wrap targetUp">{renderItem}</ul>
          <ul className="inner-wrap targetUp">{renderItem2}</ul>
        </div>
      </div>
    </div>
  );
}
