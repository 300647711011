import { endpoint } from "_common/constants/common";
import api from "states/drivers";

const RoomEndpoint = `${endpoint}/room`;

export const RoomApi = {
  getList: async ({ params }) =>
    await api
      .get(`${RoomEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api.get(`${RoomEndpoint}/detail/${id}`).then((result) => result.data),
};
