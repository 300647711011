import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import noticeImg from "assets/img/icon/notice.png";

export default function Notice() {
  const navigate = useNavigate();
  const {
    authStore: { notice },
  } = useStores();

  return (
    <div className="adm-notice-bar adm-notice-bar-alert">
      <img src={noticeImg} alt="" />
      <span className="adm-notice-bar-content">
        <span className="adm-notice-bar-content-inner target">{notice}</span>
      </span>
    </div>
  );
}
