import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";
import {
  paymentStatusColor,
  paymentStatusText,
} from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function WithdrawHistoryPage() {
  const navigate = useNavigate();

  const [params, setParams] = useState({});
  const { data, refetch } = useQuery(
    ["payment", "list_withdraw", params],
    () =>
      PaymentApi.getList({
        params: {
          type: "withdraw",
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderBody = useMemo(() => {
    if (!data?.data?.length) return <NoData description="Không có dữ liệu" />;
    return data?.data?.map((item) => (
      <div className="list-item" key={item?.id}>
        <div className="uni-text">-{formatNumber(item?.amount)}</div>
        <div className="uni-text">{paymentStatusText[item?.status]}</div>
        <div className="uni-text">
          {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
        </div>
      </div>
    ));
  }, [data]);

  return (
    <>
      <HeaderTitle title="Sổ ghi chép rút tiền" />
      <div className="container_main">
        <div className="list_header">
          <div className="uni-text">Đã thay đổi</div>
          <div className="uni-text">Chi tiết</div>
          <div className="uni-text">Thời gian</div>
        </div>
        <div className="list_content">{renderBody}</div>
      </div>
    </>
  );
}

export default WithdrawHistoryPage;
