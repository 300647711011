import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

export default function ListBanner() {
  const navigate = useNavigate();
  const {
    authStore: { banners },
  } = useStores();

  return (
    <div className="slide-home">
      <Swiper
        pagination
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="swiper2 "
      >
        {banners.map((item) => (
          <SwiperSlide key={item?.id}>
            <img alt="" src={item?.image_url} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
