import classNames from "classnames";
import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

function MarketPage() {
  const {
    authStore: { user },
  } = useStores();

  const [params] = useSearchParams();
  const activeTab = params?.get("symbol") || "USDTUSDC";

  return (
    <>
      <HeaderTitle title="Xu hướng thị trường " />
      <div className="room_header pt-40">
        <div className="room_header_left">
          <div className="room_header_info_money">
            {formatNumber(user?.balance, "0,0.[0000]")}
          </div>
          <div className="room_header_info">Số dư </div>
        </div>
        <div className="room_header_left">
          <div className="room_header_info text-right">
            Khối lượng giao dịch hôm qua: 4342988
          </div>
          <div className="room_header_info text-right">
            Khối lượng giao dịch hôm nay: 4062722
          </div>
        </div>
      </div>
      <div className="tabs">
        <div
          className={classNames("tab", {
            active: activeTab === "USDTUSDC",
          })}
          onClick={() => window.open("/market?symbol=USDTUSDC", "_self")}
        >
          USDT
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "BTCUSDC",
          })}
          onClick={() => window.open("/market?symbol=BTCUSDC", "_self")}
        >
          BTC
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "ETHUSDC",
          })}
          onClick={() => window.open("/market?symbol=ETHUSDC", "_self")}
        >
          ETH
        </div>
      </div>
      <div className="tradingview-widget-container">
        <div id="crypt-candle-chart">
          <div id="tradingview_24be9-wrapper">
            <iframe
              title="advanced chart TradingView widget"
              lang="en"
              id="tradingview_24be9"
              frameBorder="0"
              scrolling="no"
              allowfullscreen="true"
              src={`https://s.tradingview.com/widgetembed/?hideideas=1&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en#%7B%22symbol%22%3A%22${activeTab}%22%2C%22frameElementId%22%3A%22tradingview_24be9%22%2C%22interval%22%3A%22D%22%2C%22allow_symbol_change%22%3A%221%22%2C%22save_image%22%3A%221%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22Light%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22nva-financial.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22${activeTab}%22%2C%22page-uri%22%3A%22nva-financial.com%2Ftrend%22%7D`}
              style={{
                width: "100%",
                height: "100%",
                margin: "0px!important",
                padding: "0px!important",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketPage;
