import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import io from "socket.io-client";
import { socketUrl } from "_common/constants/common";
import { useQuery, useQueryClient } from "@tanstack/react-query";

let sock = null;

export const NotifyContext = createContext({});

export default function NotifyProvider({ gameId, children }) {
  const queryClient = useQueryClient();

  const [dataGame, setDataGame] = useState({
    time: 60,
    game_no: null,
    prev_game: {},
    status: "start",
  });

  const [isConnect, setIsConnect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notify, setNotify] = useState(null);
  const [newResult, setNewResult] = useState(null);
  const [gameCode1, setGameCode1] = useState();
  const [gameCode2, setGameCode2] = useState();
  const [rules, setRules] = useState([]);

  const onGetDataGame = (data) => {
    setDataGame(data);
  };

  const onConnectSocket = (e) => {
    console.log(e);
    setIsLoading(false);
    setIsConnect(true);
  };

  const onJoinRoom = (room_id) => {
    sock.emit("join_room", {
      room_id,
    });
  };

  const onLeaveRoom = (room_id) => {
    sock.emit("leave_room", {
      room_id,
    });
  };

  const pushNotify = (data) => {
    sock.emit("push_notify", {
      data,
    });
  };

  const onBetGame = (data) => {
    setNewResult(data);
    queryClient.invalidateQueries(["get_profile"]);
    queryClient.invalidateQueries(["game", "detail"]);
  };

  const onGetNotify = (data) => {
    setNotify(data);
    if (data?.data?.type === "update_user") {
      queryClient.invalidateQueries(["get_device"]);
      queryClient.invalidateQueries(["get_profile"]);
      queryClient.invalidateQueries(["game", "detail"]);
    }
  };

  const betGame = useCallback((data) => {
    sock.emit("betGame", { ...data });
  }, []);

  useEffect(() => {
    if (!gameId) return null;
    if (!isConnect) return null;
    onJoinRoom("level_2");

    return () => {
      onLeaveRoom("level_2");
    };
  }, [gameId, isConnect]);

  useEffect(() => {
    sock = io(socketUrl, {
      transports: ["websocket"],
    });

    sock.on("open", onConnectSocket);
    sock.on("dataGame", onGetDataGame);
    sock.on("bet-game", onBetGame);
    sock.on("push_notify", onGetNotify);
    return () => {
      sock.close();
    };
  }, []);

  const returnDefault = useMemo(() => {
    const code = 5;

    return {
      isLoading,
      notify,
      dataGame: { ...dataGame },
      newResult,
      gameCode1,
      gameCode2,
      rules,
      setRules,
      setGameCode1,
      setGameCode2,
      pushNotify,
      setIsLoading,
      setNotify,
      betGame,
      onJoinRoom,
      onLeaveRoom,
      sock,
    };
  }, [betGame, rules, gameCode1, dataGame]);

  return (
    <NotifyContext.Provider value={returnDefault}>
      {children}
    </NotifyContext.Provider>
  );
}

export function useNotify() {
  return useContext(NotifyContext);
}
